import React from "react"
import SEO from "../components/seo"
import SecondaryHeader from "../components/secondary-header"
import CreateAccount from "./../components/create-account/index"

const CreateAccountPage = ({ data }) => {
  const metaData = data.allContentfulCreateAccount.nodes[0]

  return (
    <>
      <SecondaryHeader />
      <SEO title="Create Account" description={metaData.metaDescription} />
      <CreateAccount />
    </>
  )
}

export const query = graphql`
  {
    allContentfulCreateAccount {
      nodes {
        metaDescription
      }
    }
  }
`

export default CreateAccountPage
