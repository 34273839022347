import React from "react"
import * as Yup from "yup"
import { Link, navigate } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { Formik, Field, Form } from "formik"
import countriesList from "../common/countriesList"
import "./_index.scss"

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
  companyName: Yup.string().required("Company Name is Required"),
  address: Yup.string().required("Address is Required"),
  city: Yup.string().required("City is Required"),
  country: Yup.string().required("Country is Required"),
  companyNumber: Yup.string().required("Org./Personal number is required"),
  terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
})

const initialValues = {
  "bot-field": "",
  "form-name": "create-account",
  email: "",
  password: "",
  passwordConfirmation: "",
  companyName: "",
  address: "",
  zipCode: "",
  city: "",
  country: "",
  phone: "",
  vat: "",
  companyNumber: "",
  terms: false,
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const CreateAccount = () => {
  const handleSubmit = payload => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "create-account", ...payload }),
    })
      .then(() => navigate("/thank-you", { replace: true }))
      .catch(error => console.log(error))
  }

  return (
    <section id="create-account-section">
      <Container>
        <Row className="justify-content-center signup-form-wrapper">
          <Col lg={5} md={8} className="my-auto">
            <Formik
              initialValues={initialValues}
              validationSchema={SignUpSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form
                  className="form-signup"
                  name="create-account"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="create-account"
                  />

                  <Row>
                    <Col className="text-center form-head-text">
                      <img
                        src={require("../../../static/logos/create-account-icon.svg")}
                        alt="logo"
                      />
                      <h1 className="">Let’s create your account!</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email*"
                          className={
                            errors.email && touched.email
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.email && touched.email ? (
                          <div className="invalid-feedback">{errors.email}</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Field
                          name="password"
                          type="password"
                          placeholder="Password*"
                          className={
                            errors.password && touched.password
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.password && touched.password ? (
                          <div className="invalid-feedback">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          placeholder="Repeat password*"
                          className={
                            errors.passwordConfirmation &&
                            touched.passwordConfirmation
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.passwordConfirmation &&
                        touched.passwordConfirmation ? (
                          <div className="invalid-feedback">
                            {errors.passwordConfirmation}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Field
                          name="companyName"
                          type="text"
                          placeholder="Name/Company name*"
                          className={
                            errors.companyName && touched.companyName
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.companyName && touched.companyName ? (
                          <div className="invalid-feedback">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Field
                          name="address"
                          type="text"
                          placeholder="Address*"
                          className={
                            errors.address && touched.address
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.address && touched.address ? (
                          <div className="invalid-feedback">
                            {errors.address}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Field
                          name="zipCode"
                          type="text"
                          className="form-control zip-code-input"
                          placeholder="Zip Code"
                        />
                      </div>

                      <Row>
                        <Col md={6} className="pr-md-2">
                          <div className="form-group">
                            <Field
                              name="city"
                              type="text"
                              placeholder="City*"
                              className={
                                errors.city && touched.city
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors.city && touched.city ? (
                              <div className="invalid-feedback">
                                {errors.city}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6} className="pl-md-2">
                          <div className="form-group">
                            <Field
                              as="select"
                              name="country"
                              className={
                                errors.country && touched.country
                                  ? "form-control drop-down is-invalid"
                                  : "form-control drop-down"
                              }
                            >
                              <option defaultValue>Country*</option>
                              {countriesList.map((country, index) => (
                                <option value={country.name} key={index}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            {errors.country && touched.country ? (
                              <div className="invalid-feedback">
                                {errors.country}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} className="pr-md-2">
                          <div className="form-group">
                            <Field
                              name="phone"
                              type="text"
                              className="form-control"
                              placeholder="Phone number"
                            />
                          </div>
                        </Col>

                        <Col md={6} className="pl-md-2">
                          <div className="form-group">
                            <Field
                              name="companyNumber"
                              type="text"
                              placeholder="Org./Personal Number*"
                              className={
                                errors.companyNumber && touched.companyNumber
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors.companyNumber && touched.companyNumber ? (
                              <div className="invalid-feedback">
                                {errors.companyNumber}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group">
                        <Field
                          name="vat"
                          type="text"
                          className="form-control"
                          placeholder="VAT number"
                        />
                      </div>

                      <div className="form-group">
                        <div className="checkbox">
                          <label
                            className={
                              errors.terms && touched.terms ? "is-invalid" : ""
                            }
                          >
                            <Field name="terms" type="checkbox" />
                            Tick box that user accepts our{" "}
                            <a href="#">terms of use</a>.
                          </label>
                        </div>
                        {errors.terms && touched.terms ? (
                          <div className="invalid-feedback">{errors.terms}</div>
                        ) : null}
                      </div>

                      <button
                        className="btn btn-lg btn-block sign-in-btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create account
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <Row>
              <Col className="my-3 text-center sign-in-text">
                <p>
                  Don’t have an account? <Link to="/sign-in">Sign in</Link>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className="p-0 bottom-rectangle position-absolute">
            <img
              className="img-fluid w-100"
              src={require("../../../static/rectangle.svg")}
              alt="rectangle"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CreateAccount
